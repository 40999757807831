$fontName: 'Poppins', sans-serif;

@import '../src/assets/demo/default/skins/header/base/light.css';
@import '../src/assets/demo/default/skins/header/menu/light.css';
@import '../src/assets/demo/default/skins/brand/dark.css';
@import '../src/assets/demo/default/skins/aside/dark.css';

@import '../src/assets/demo/default/base/style.bundle.css';
@import '../src/assets/vendors/base/vendors.bundle.css';

@import '../src/assets/vendors/base/vendors.bundle.css';

@import '~material-design-icons/iconfont/material-icons.css';
@import '~@ng-select/ng-select/themes/default.theme.css';


@import '../src/assets/app/custom/nislo/table.scss';
@import '../node_modules/ngx-toastr/toastr.css';

.mat-dialog-container {
  height: unset !important;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: $fontName  !important;
}

body.scroll-lock {
  overflow: hidden !important;
}

a {
  color: #5867dd;
  text-decoration: none !important;
  background-color: transparent;
}

.toast-container {
  z-index: 999 !important;
}

/* header space  */
.kt-header--fixed.kt-subheader--fixed.kt-subheader--enabled .kt-wrapper {
  padding-top: 80px !important;
}

/*
  ng-select css update.
*/
.ng-select .ng-select-container {
  min-height: 38px;
}

.ng-select .ng-select-container {
  border-color: #ced1e2;
}

.form-control.ng-select {
  border: none;
  padding: 0;
}

.gray-text {
  color: #a1a6c0 !important;
}

.f12 {
  font-size: 12px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-nowrap {
  white-space: nowrap;
}

.star-item {
  position: relative;
  font-size: 12px;
  color: rgba(216, 216, 216, 0.5);
  margin-left: 2px;
}

.star-item-filled,
.star-item-full {
  color: #ffb822;
}

.star-item-half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: #ffb822;
}

.inputClear {
  font-size: 18px;
  font-weight: 500;
  color: #999;
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 15px;
}

.inputClear:hover {
  color: #c00;
}

.bgclear {
  background-image: none !important;
}

/*****************************************************************/
body,
* {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.dropdown-custom {
  position: relative;
  z-index: 2;
}

.dropdown-custom .dropdown-menu {
  width: 100%;
}

.dropdown-custom button.btn {
  position: relative;
  width: 100%;
  height: 42px;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px #ebedf2;
  font-size: 13px;
  text-align: left;
  color: #aeb2c8;
}

.dropdown-custom button.btn:before {
  position: absolute;
  display: block;
  content: ' ';
  width: 98%;
  height: 2px;
  top: 2px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(108, 114, 147, 0.4);
}

.dropdown-custom button.btn:after {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 16px;
  margin: auto;
  height: 13px;
  content: '\f1b1';

  font-size: 8px;
  font-family: 'Flaticon2';
  font-style: normal;
  font-weight: 900;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.dropdown-custom.dropdown-blue button.btn:before {
  background-color: #6495ed;
}

.dropdown-custom.dropdown-yellow button.btn:before {
  background-color: #f5942a;
}

.dropdown-custom.dropdown-green button.btn:before {
  background-color: #0fbb87;
}

.dropdown-custom.dropdown-red button.btn:before {
  background-color: #fd397a;
}

.dropdown-custom button.btn-color {
  color: #555;
}

.dropdown-custom-menu {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px #ebedf2;
}

.dropdown-custom-menu .dropdown-search input,
.dropdown-custom .dropdown-search input {
  position: relative;
  display: block;
  width: 100%;
  height: 48px;
  line-height: 48px;
  outline: none;
  border: none;
  padding: 0 35px 0 10px;
  border-bottom: 1px solid #ebedf2;
  font-size: 13px;
}

.dropdown-custom-menu .dropdown-search input::placeholder,
.dropdown-custom .dropdown-search input::placeholder {
  color: #aeb2c8;
}

.dropdown-custom-menu .dropdown-search .btn-dropdown-search,
.dropdown-custom .dropdown-search .btn-dropdown-search {
  position: absolute;
  display: flex;
  align-items: center;
  right: 10px;
  top: 0;
  height: 48px;
}

.dropdown-custom .dropdown-treeview ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: #aeb2c8;
  line-height: 28px;
}

.dropdown-custom .dropdown-treeview-content li {
  padding-left: 14px;
  vertical-align: baseline;
  cursor: pointer;
  color: #555555;
}

.dropdown-custom .dropdown-treeview-content li i {
  display: inline-block;
  font-size: 8px;
  margin-right: 5px;
  vertical-align: top;
}

.dropdown-custom .dropdown-treeview-content li li {
  padding-left: 20px;
}

.dropdown-custom .dropdown-treeview-content li.opened i {
  transform: rotate(90deg);
}

.dropdown-custom .dropdown-treeview-content li.selected {
  color: #6381ff;
}

.dropdown-custom .dropdown-treeview-content li i.nislo-dd-ico-close {
  display: inline-block;
}

.dropdown-custom .dropdown-treeview-content li i.nislo-dd-ico-open {
  display: none;
  transform: rotate(0);
  color: #6381ff;
  vertical-align: middle;
  font-size: 12px;
  margin-right: 2px;
}

.dropdown-custom .dropdown-treeview-content li.selected i.nislo-dd-ico-close {
  display: none;
}

.dropdown-custom .dropdown-treeview-content li.selected i.nislo-dd-ico-open {
  display: inline-block;
}

.dropdown-custom .dropdown-treeview-content li ul.nested {
  display: none;
}

.dropdown-custom .dropdown-treeview-content li.opened ul.active {
  display: block;
}

.keyword-custom {
  position: relative;
  width: 100%;
  height: 42px;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px #ebedf2;
  font-size: 13px;
  text-align: left;
  color: #aeb2c8;
}

.keyword-custom:before {
  position: absolute;
  display: block;
  content: ' ';
  width: 98%;
  height: 2px;
  top: 2px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #bcc0ce;
}

.keyword-custom input {
  position: relative;
  display: block;
  width: 100%;
  height: 42px;
  border: none;
  outline: none;
  background: transparent;
  padding: 0 26px 0 13px;
}

.keyword-custom .btn-keyword-search {
  position: absolute;
  display: flex;
  align-items: center;
  right: 10px;
  top: 0;
  height: 42px;
  color: #aeb2c8;
}

/*****/
.btn-find,
.btn-find:hover {
  background-color: #5c77ff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #fff !important;
  width: 100px !important;
  height: 40px !important;
}

.btn-reset,
.btn-reset:hover {
  background-color: #f5f5f9 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #6381ff !important;
  width: 100px;
  height: 40px;
  margin-right: 20px;
}

.btn-select,
.btn-select:hover {
  width: 100px;
  height: 40px;
  background-color: #f5f5f9 !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #6381ff !important;
}

.btn-blue,
.btn-blue:hover {
  background-color: #5c77ff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #fff !important;
  width: 140px !important;
  height: 40px !important;
}

.btn-green,
.btn-green:hover {
  background-color: #0fbb87;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #fff !important;
  width: 140px !important;
  height: 40px !important;
}

.btn-blue.btn-small {
  font-size: 10px !important;
  width: 65px !important;
  height: 30px !important;
  padding: 0 !important;
}

.btn-blue.btn-small2,
.btn-blue.btn-small2:hover {
  font-size: 11px !important;
  width: 100px !important;
  height: 30px !important;
  padding: 0 !important;
}

.btn-white,
.btn-white:hover {
  background-color: #fff;
  border-color: #a1a6c0;
  font-size: 13px;
  font-weight: 500;
  color: #a1a6c0;
  width: 110px;
  height: 40px;
}

.btn-red,
.btn-red:hover {
  background-color: #d87087 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #fff !important;
  width: 140px !important;
  height: 40px !important;
}

/******/
.divider {
  border-left: 1px solid #e4e4e4;
  margin-left: 25px;
  margin-right: 25px;
}

.tag-container {
  position: relative;
  display: block;
  align-items: center;
  z-index: 1;
}

.tag-container .tagItem {
  position: relative;
  display: inline-block;
  border-radius: 2px;
  background: #f7f8fa;
  margin: 5px 10px 5px 0;
  height: 30px;
  line-height: 30px;
  font-weight: 500;
  color: #6c7293;
  padding: 0 10px 0 10px;
  font-family: $fontName;
  /* font-size:12px;*/
}

.tag-container .tagItem i {
  color: #ccd0d7;
  font-size: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: #ccd0d7;
  font-size: 8px;
  margin-left: 18px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
}

.tag-container .tagItem i:after {
  font-family: Flaticon2;
  content: '\f103';
  position: absolute;
  display: block;
  cursor: pointer;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #ccd0d7;
}

.tag-container .tagItem:after {
  position: absolute;
  display: block;
  content: ' ';
  background-color: #bcc0ce;
  height: 2px;
  width: calc(100% - 2px);
  border-radius: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.tag-container .tagItem.tagItem-blue:after {
  background-color: #6495ed;
}

.tag-container .tagItem.tagItem-yellow:after {
  background-color: #f5942a;
}

.tag-container .tagItem.tagItem-green:after {
  background-color: #0fbb87;
}

.tag-container .tagItem.tagItem-red:after {
  background-color: #fd397a;
}

.tag-container .tagItem.tagItem-gray:after {
  background-color: #bcc0ce;
}

.tag-container-light .tagItem:after,
.tag-container-light .tagItem i:after {
  display: none;
}

/****/

.pageSubHeader {
  position: relative;
  display: flex;
  line-height: 23px;
  align-items: center;
}

.pageSubHeader .page-title {
  font-family: $fontName;
  font-weight: 500;
  font-size: 1.4rem;
  color: #464457;
  margin: 0;
}

.pageSubHeader .page-desc {
  font-family: $fontName;
  font-weight: 500;
  /*font-size: 12px;*/
  color: #464457;
  opacity: 0.5;
}

.pageSubHeader .page-desc span {
  font-family: $fontName;
  font-weight: 600;
}

.pageSubHeader .page-separator {
  width: 1px;
  height: 19px;
  background-color: #d4d7e6;
  margin: 0 20px;
}

.projectInfo {
  font-size: 13px;
  font-weight: 500;
  color: #595d6e;
}

.projectInfo span {
  color: #a1a6c0;
}

/******/
.nislo-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

.nislo-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.nislo-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  border: solid 1px #d1d7e2;
  background-color: #eceff4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.nislo-switch .slider:before {
  position: absolute;
  content: '';
  width: 14px;
  height: 14px;
  left: 1px;
  bottom: 1px;
  background-color: #d1d7e2;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.nislo-switch input:checked+.slider {
  border: solid 1px #2fbd6d;
  background-color: #a7e6c3;
}

.nislo-switch input:checked+.slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
  background-color: #50ce87;
}

.nislo-switch-panel {
  position: relative;
  display: flex;
}

.nislo-switch-panel .title {
  font-family: $fontName;
  font-weight: 500;
  color: #6c7293;
  padding: 0 10px;
  font-size: 1rem;
}

/*********/
.btnTabArea {
  position: relative;
  display: flex;
}

.btnTabArea a {
  position: relative;
  display: block;
  margin-left: 10px;
  width: 29px;
  height: 29px;
  text-indent: -9999px;
  background: url(assets/media/nisloimg/tabViewSprite.png) 0 0 no-repeat;
}

.btnTabArea a.listview {
  background-position: 0 0;
}

.btnTabArea a.cardview {
  background-position: -30px 0;
}

.btnTabArea a.listview.selected {
  background-position: 0 -30px;
}

.btnTabArea a.cardview.selected {
  background-position: -30px -30px;
}

/*****/

/***/

.table-workload th {
  font-size: 12px;
  font-weight: 500;
}

.table-workload th,
.table-workload td {
  padding: 10px;
  border-right: 1px solid #d4d7e6;
  border-bottom: 1px solid #f2f3f8;
}

.table-workload th.bdr,
.table-workload td.bdr {
  border-right: 1px solid #f5f5f9;
}

.table-workload th:nth-last-child(-n + 2),
.table-workload td:nth-last-child(-n + 2) {
  padding-right: 0;
  border-right: none;
}

.table-workload tr.date-area th {
  color: #a1a6c0;
  padding-bottom: 30px;
  border-bottom: none;
}

.table-workload tbody td {
  font-size: 11px;
  font-weight: 600;
  color: #595d6e;
}

.table-workload tbody td:first-child {
  font-weight: 500;
  color: #494b74;
}

.table-workload tbody td.text-red {
  color: #db7979;
}

.table-workload tbody td.text-yellow {
  color: #f4a661;
}

.table-workload tbody td.text-blue {
  color: #5f7cf6;
}

.table-workload tbody td.text-green {
  color: #50ce87;
}

.table-workload .badge-supervisor {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  border: solid 1px #a1a6c0;
  background-color: #f6f6f6;
  line-height: 30px;
  text-align: center;
  border-radius: 30px;
  margin-right: 5px;
  font-family: $fontName;
  font-size: 9.6px;
  font-weight: 600;
  color: #595d6e;
}

/***/

.badge-green {
  color: #308f5a !important;
  background-color: #c8f3cf !important;
}

.badge-lightgreen {
  color: #308f5a !important;
  background-color: rgba(145, 231, 160, 0.5) !important;
}

.badge-yellow {
  color: #969049 !important;
  background-color: #fbf38d !important;
}

.badge-orange {
  color: #c57e3f !important;
  background-color: #f9d2b0 !important;
}

.badge-gray {
  color: #6c7293 !important;
  background-color: #e9ebf2 !important;
}

.badge-white {
  color: #a1a6c0 !important;
  background-color: #fff;
  border: 1px solid rgba(108, 114, 147, 0.5) !important;
}

.badge-darkgray {
  color: #fff !important;
  background-color: #6c7293 !important;
}

.badge-purple {
  color: #521989 !important;
  background-color: #ccccec !important;
}

.badge-darkblue {
  background-color: #6c7293;
}

.badge-darkred {
  background-color: #d66377;
}

.badge-darkyellow {
  background-color: #d7aa32;
}

.badge-darkgreen {
  background-color: #469c6b;
}

/****/

.projects-nav-container {
  display: flex;
  justify-content: space-between;
}

ul.writer-profile-tab-nav {
  width: 100%;
  background-color: #e5e7f0;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

ul.writer-profile-tab-nav li {
  position: relative;
  display: inline-block;
  background-color: transparent;
}

ul.writer-profile-tab-nav li a {
  padding: 12px 10px;
  font-size: 12px;
  font-weight: 500;
  color: #a1a6c0;
  min-width: 180px;
}

ul.writer-profile-tab-nav li a span {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 600;
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.7);
}

ul.writer-profile-tab-nav li a span:before {
  position: absolute;
  display: block;
  content: ' ';
  width: 8px;
  height: 8px;
  border-radius: 10px;
  top: 0;
  left: 0;
}

ul.writer-profile-tab-nav li a span.online:before {
  background-color: #50ce87;
}

ul.writer-profile-tab-nav li a span.offline:before {
  background-color: #d87087;
}

ul.writer-profile-tab-nav li a span.away:before {
  background-color: #f4a661;
}

ul.writer-profile-tab-nav li a.active {
  color: #6c7293;
  background-color: #fff;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nislo-tab-content {
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

/* writer list level 2*/
ul.writer-profile-section-nav {
  width: 100%;
  border-bottom: 1px solid #f2f3f8;
  padding: 0 30px;
}

ul.writer-profile-section-nav li {
  margin-right: 30px;
}

ul.writer-profile-section-nav li a {
  color: #a1a6c0;
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0 14px 20px;
}

ul.writer-profile-section-nav li a.niso-tab-projects {
  background: url(assets/media/nisloimg/ico-projects.svg) no-repeat left center;
}

ul.writer-profile-section-nav li a.niso-tab-calendar {
  background: url(assets/media/nisloimg/ico-calendar.svg) no-repeat left center;
}

ul.writer-profile-section-nav li a.niso-tab-info {
  background: url(assets/media/nisloimg/ico-info.svg) no-repeat left center;
}

.writer-profile-section-nav ul li a div .niso-tab-note {
  background: url(assets/media/nisloimg/notes.svg) no-repeat left center;
}

ul.writer-profile-section-nav li a.active {
  color: #2b2b43;
  border-bottom: 4px solid #7d92ff;
}

ul.writer-profile-section-nav li a.niso-tab-projects.active {
  background: url(assets/media/nisloimg/ico-projects-active.svg) no-repeat left center;
}

ul.writer-profile-section-nav li a.niso-tab-calendar.active {
  background: url(assets/media/nisloimg/ico-calendar-active.svg) no-repeat left center;
}

ul.writer-profile-section-nav li a.niso-tab-info.active {
  background: url(assets/media/nisloimg/ico-info-active.svg) no-repeat left center;
}

ul.writer-profile-section-nav li a.niso-tab-note.active {
  background: url(assets/media/nisloimg/notes-active.svg) no-repeat left center;
}

.nislo-section-content {
  padding: 30px;
}

/* writer list level 3 */

.projectsNavContainer {
  overflow-x: hidden;
  margin-bottom: -1px;
  margin-right: 40px;
}

.tabNavArea a,
.tabNavArea a:focus {
  outline: 0 !important;
}

.tabNavArea a i {
  color: #abb0c7;
  font-size: 12px;
}

.writer-profile-projects-nav {
  width: 100%;
  display: flex;
}

.writer-profile-projects-nav li a {
  padding: 12px 30px;
  font-size: 12px;
  white-space: nowrap;
}

.writer-profile-projects-nav li a span {
  color: #a1a6c0;
  padding-left: 10px;
}

.writer-profile-projects-nav li a.active {
  padding: 12px 29px;
  border: 1px solid #d4d7e6;
  border-bottom: 0;
  background-color: #fff;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nislo-projects-content {
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #d4d7e6;
  padding: 30px 30px 0;
}

/* writer profile page objects */

.fieldBadge {
  display: flex;
  align-items: center;
}

.fieldBadge .name-badge {
  position: relative;
  display: flex;
  width: 60px;
  height: 60px;
  // margin-right: 20px;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: $fontName;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.fieldBadge .name-badge.badge-clr-darkblue,
.badge-clr-darkblue {
  background-color: rgba(108, 114, 147, 1);
  /*#6c7293;*/
}

.fieldBadge .name-badge.badge-clr-darkred,
.badge-clr-darkred {
  background-color: rgba(214, 99, 119, 1);
  /*#d66377;*/
}

.fieldBadge .name-badge.badge-clr-darkyellow,
.badge-clr-darkyellow {
  background-color: rgba(215, 170, 50, 1);
  /*#d7aa32;*/
}

.fieldBadge .name-badge.badge-clr-darkgreen,
.badge-clr-darkgreen {
  background-color: rgba(70, 156, 107, 1);
  /*#469c6b;*/
}

.fieldBadge .name-badge.badge-clr-gray,
.badge-clr-gray {
  background-color: rgb(153, 158, 155);
  /*#469c6b;*/
}

.fieldBadge .name-badge.badge-clr-white {
  background-color: #fff;
  color: #000 !important;
  border: solid 1px #a1a6c0;
  /*#469c6b;*/
}

.fieldBadge .name-badge.badge-clr-orange,
.badge-clr-orange {
  background-color: rgb(219, 123, 44);
  /*#6c7293;*/
}

.fieldBadge .name-badge.badge-clr-blue,
.badge-clr-blue {
  background-color: rgb(66, 84, 190);
}

.fieldBadge .name-badge.badge-supervisor {
  border: solid 1px #a1a6c0;
  background-color: #f6f6f6;
  color: #595d6e;
}

.fieldBadge .name-badge .fav {
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  color: #d4d7e6;
  font-size: 15px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 25px;
}

.fieldBadge .name-badge .fav-red {
  color: #fd397a;
}

.fieldBadge .writer-name {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: $fontName;
  font-size: 16px;
  font-weight: 500;
  color: #595d6e;
  white-space: nowrap;
}

.fieldBadge .writer-name span {
  position: relative;
  display: block;
  color: #9b9b9b;
  font-size: 11px;
  font-weight: 500;
  font-family: $fontName;
  line-height: 14px;
}

.fieldBadge .writer-name span i {
  position: relative;
  display: inline-block;
  font-style: normal;
  font-family: monospace;
  font-size: 30px;
  line-height: 14px;
  vertical-align: bottom;
  margin-left: -5px;
  margin-right: -3px;
}

.fieldBadge .writer-name span.online i {
  color: #50ce87;
}

.fieldBadge .writer-name span.offline i {
  color: #d87087;
}

.fieldBadge .writer-name span.away i {
  color: #f4a661;
}

.fieldBadgeSmall .name-badge {
  width: 50px;
  height: 50px;
  font-size: 16px;
}

.fieldBadgeSmall .name-badge .fav {
  right: -5px;
  bottom: -5px;
}

/**/

.writer-dashboard .panel-container .qview {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.writer-dashboard .panel-container .qview .qstatus {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  border: 2px solid black;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  color: #a1a6c0;
}

.writer-dashboard .panel-container .qview .qstatus.border-darkblue,
.border-darkblue {
  border-color: #6c7293 !important;
}

.writer-dashboard .panel-container .qview .qstatus.border-darkred,
.border-darkred {
  border-color: #d66377 !important;
}

.writer-dashboard .panel-container .qview .qstatus.border-darkyellow,
.border-darkyellow {
  border-color: #d7aa32 !important;
}

.writer-dashboard .panel-container .qview .qstatus.border-darkgreen,
.border-darkgreen {
  border-color: #469c6b !important;
}

.writer-dashboard .panel-container .qview .qstatus.border-gray,
.border-gray {
  border-color: #a1a6c0 !important;
}

.writer-dashboard .panel-container .qview .qtext {
  font-size: 12px;
  font-weight: 500;
  color: #494b74;
}

.writer-dashboard .panel-container .qview .qtext span {
  display: block;
  color: #a8abbd;
}

/**/
.report-list {
  width: 180px;
}

.report-list ul,
.report-list ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.report-list ul li {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-list ul li:before {
  position: absolute;
  display: block;
  content: ' ';
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(212, 215, 230, 0.3);
  z-index: 1;
}

.report-rate ul li:before,
.report-bar ul li:before {
  display: none;
}

.report-list ul li span {
  display: block;
  background-color: #fff;
  z-index: 2;
  color: #595d6e;
  font-size: 11px;
  font-weight: 500;
  line-height: 19px;
}

.report-list ul li span:first-child {
  padding-right: 10px;
}

.report-list ul li span:last-child {
  padding-left: 10px;
  font-weight: 600;
  color: #a1a6c0;
}

.report-list div.title {
  font-size: 12px;
  font-weight: 500;
  color: #15161d;
  margin-bottom: 10px;
}

.report-rate {
  width: 200px;
}

.report-rate ul li {
  margin-bottom: 5px;
}

.report-rate div.title {
  font-size: 13px;
  font-weight: 500;
  color: #2b2b43;
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f7f7f7;
}

.report-bar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.report-bar .line-container {
  display: flex;
  height: 100%;
}

.report-bar .line {
  height: 100%;
  margin: 0;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.report-bar .line:last-child {
  margin-right: 0;
}

.report-bar .level,
.report-bar .bar {
  margin: 0;
}

.report-bar .level {
  font-size: 12px;
  font-weight: 600;
  color: #d4d7e6;
  text-align: center;
}

.report-bar .bar {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}

.report-bar .bar span {
  color: #595d6e;
  opacity: 0.5;
  font-size: 10px;
  font-weight: 500;
}

.report-bar .bar i {
  width: 8px;
  display: block;
  border-radius: 4px;
  background-color: #6c7293;
}

.report-bar .bar i.color1,
.report-bar2 .bar i.color1 {
  opacity: 1;
}

.report-bar .bar i.color2,
.report-bar2 .bar i.color2 {
  opacity: 0.8;
}

.report-bar .bar i.color3,
.report-bar2 .bar i.color3 {
  opacity: 0.7;
}

.report-bar .bar i.color4,
.report-bar2 .bar i.color4 {
  opacity: 0.5;
}

.report-bar .bar i.color5,
.report-bar2 .bar i.color5 {
  opacity: 0.4;
}

.report-bar2 .bar i.dark {
  background-color: #727797 !important;
}

.report-bar2 .bar i.red {
  background-color: #d87087 !important;
}

.report-bar2 .bar i.gray {
  background-color: #a4a8bc !important;
}

.report-bar2 .bar i.blue {
  background-color: #8297ff !important;
}

.report-bar2 {
  width: 220px;
}

.report-bar2 .headItems {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #d4d7e6;
}

.report-bar2 .line {
  display: flex;
  justify-content: space-between;
  color: #595d6e;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.4em;
}

.report-bar2 .line .bar {
  display: flex;
  align-items: center;
}

.report-bar2 .line .bar span {
  color: #a1a6c0;
  padding-left: 10px;
}

.report-bar2 .line .bar i {
  height: 8px;
  display: block;
  border-radius: 4px;
  background-color: #6c7293;
}

/***/

.writerInfo {
  display: flex;
}

.writerInfo .section {
  padding: 10px 30px;
  border-right: 1px solid #d4d7e6;
}

.writerInfo .section:first-child {
  padding-left: 0;
}

.writerInfo .section:last-child {
  border: none;
  padding-right: 0;
}

.writerInfo .section .title {
  font-size: 13px;
  font-weight: 500;
  color: #2b2b43;
  margin-bottom: 20px;
}

.writerInfo .section .description strong {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #6c7293;
  margin-bottom: 3px;
}

.writerInfo .section .description strong i {
  color: #8297ff;
  font-size: 14px;
  margin-left: 5px;
}

.writerInfo .section .description p {
  font-size: 12px;
  color: #a1a6c0;
  line-height: 18px;
  margin-bottom: 20px;
}

.writerInfo .btnWriterInfo {
  position: relative;
  display: block;
  width: 280px;
  border: 1px solid #d4d7e6;
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  color: #6c7293;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  margin-bottom: 20px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.writerInfo .btnWriterInfo i {
  margin-right: 10px;
  color: #3c61ff;
}

.writerInfo .btn-blue {
  width: 200px;
  height: 40px;
}

/*
CIRCLE BAR DEMO
*/

.progress {
  width: 150px;
  height: 150px;
  background: none;
  position: relative;
}

.progress::after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 10px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress .progress-value {
  position: absolute;
  top: 0;
  left: 0;
}

/*
CIRCLE BAR DEMO END
*/

/* niso-form */

.nislo-form {
  padding: 5px 0;
}

.nislo-form label {
  display: block;
  font-size: 13px;
  color: #595d6e;
  font-weight: 500;
  margin-bottom: 10px;
}

.nislo-form label.small {
  display: block;
  font-size: 11px;
  color: #a1a6c0;
  font-weight: 500;
  margin-bottom: 0;
}

.nislo-form .bootstrap-select {
  width: 100% !important;
}

.nislo-form .bootstrap-select.disabled button {
  border-color: #d4d7e6 !important;
}

.nislo-form .kt-checkbox>span:after,
.nislo-form .kt-radio>span:after {
  border-color: #8297ff;
}

.nislo-form .form-group p {
  display: block;
  font-size: 13px;
  color: #595d6e;
  font-weight: 500;
}

.nislo-form .rating-area {
  margin-bottom: 5px;
}

.nislo-form .rating-area .rating-title {
  font-size: 11px;
  font-weight: 500;
  color: #a1a6c0;
}

/****/
.nislo-form .form-control,
.form-control,
.nislo-form .bootstrap-select>.dropdown-toggle.btn-light,
.bootstrap-select>.dropdown-toggle.btn-light,
.nislo-form .bootstrap-select>.dropdown-toggle.btn-secondary,
.bootstrap-select>.dropdown-toggle.btn-secondary,
.bootstrap-touchspin *,
.ng-select.ng-select-opened>.ng-select-container {
  border-color: #d4d7e6;
  /*
  border-color: #dee1e5; border-width:2px; background-color: #fafbfc;
  */
}

.popover-body {
  min-width: 160px;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #6c7293 !important;
}

#NisloModal .nislo-dropdown .btn:after {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -4px;
  color: rgba(108, 114, 147, 1) !important;
}

/****/

.explain {
  display: flex;
  align-items: center;
  height: 90px;
  padding: 0 40px;
  margin: 30px 0;
  border-radius: 4px;
  background-color: #f5f5f9;
}

.explain i {
  margin-right: 10px;
  font-size: 16px;
  color: #8297ff;
}

input.ico-calendar,
div.ico-calendar {
  background: url(assets/media/nisloimg/ico-calendar.svg) 97% center no-repeat;
}

input.ico-list {
  background: url(assets/media/nisloimg/ico-projects.svg) 97% center no-repeat !important;
}

button.ico-calendar {
  background: url(assets/media/nisloimg/ico-calendar.svg) center center no-repeat;
}

.copy-area {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  width: fit-content;
}

.copy-area:after {
  font-family: "Font Awesome 5 Free";
  content: "\f0c5";
  opacity: 0;
  right: -20px;
  transition: 0.5s;
  font-size: 14px;
  margin-left: 7px;
}


.copy-area:hover {
  padding-right: 8px;
  padding-left: 8px;
  // border: 1px dotted gray;
  border-radius: 10px;
  border: 1px solid #d4d7e6;

}

.copy-area:hover:after {
  opacity: 1;
  right: 10px;
}

//@at-root
.navigate-area {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  width: fit-content;
}

.navigate-area:after {
  font-family: "Font Awesome 5 Free";
  content: "\f35d";
  opacity: 0;
  right: -20px;
  transition: 0.5s;
  font-weight: 900;
  margin-left: 7px;
}


.navigate-area:hover {
  padding-right: 8px;
  padding-left: 8px;
  // border: 1px dotted gray;
  border-radius: 10px;
  border: 1px solid #d4d7e6;

}

.navigate-area:hover:after {
  opacity: 1;
  right: 10px;
}

/*****/

#NisloModal .modal-content {
  padding: 30px;
}

#NisloModal {
  width: 480px;
}

#NisloModal.modal-medium {
  width: 720px;
}

#NisloModal.modal-mediumx {
  width: 880px;
}

#NisloModal.modal-largest {
  width: 1280px;
}

@media (max-width: 1500px){
  #NisloModal.modal-largestx {
    max-width: 100% !important;
  }
}

#NisloModal.modal-largestx {
  width: 1140px;
}

#NisloModal .nislo-dropdown .btn {
  font-size: 11px;
  font-weight: 500;
  color: #a1a6c0;
  text-align: left;
  border: 1px solid #d4d7e6;
  padding: 3px 10px 3px 3px;
  border-radius: 20px;
  min-width: 130px;
}

.mh-90 {
  max-height: 90vh;
}

#NisloModal .nislo-dropdown .btn:after {
  color: #d4d7e6;
}

#NisloModal .nislo-dropdown .name-badge {
  display: inline-block;
  width: 24px;
  height: 24px;
  font-size: 9px;
  margin-right: 5px;
  margin-left: 0;
  padding: 6px;
}

#NisloModal .nislo-dropdown li a {
  font-size: 11px;
  font-weight: 500;
  color: #a1a6c0;
  padding-left: 5px;
  line-height: 24px;
}

#NisloModal .nislo-dropdown li a .name-badge {
  padding: 0;
}

#NisloModal .nislo-dropdown .dropdown-menu {
  width: 100%;
  min-width: auto;
  padding: 0;
  border: 1px solid #d4d7e6 !important;
  border-radius: 10px;
}

#NisloModal .nislo-dropdown .dropdown-menu li a {
  cursor: pointer;
}

#NisloModal .month-view {
  position: relative;
  width: 216px;
  display: flex;
}

#NisloModal .month-view button {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 38px;
  border: 1px solid #d4d7e6;
  font-size: 10px;
  background-color: #fff;
}

#NisloModal .month-view button i {
  color: #abb0c7;
}

#NisloModal .month-view button.next-button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

#NisloModal .month-view button.prev-button {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#NisloModal .month-view h2 {
  position: relative;
  display: inline-block;
  background-color: #fff;
  width: 136px;
  height: 38px;
  border: 1px solid #d4d7e6;
  border-width: 1px 0;
  font-size: 13px;
  font-weight: 500;
  color: #a1a6c0;
  text-align: center;
  line-height: 38px;
}

#NisloModal .dnote {
  font-size: 11px;
  font-weight: 500;
  color: #a1a6c0;
}

#NisloModal .modal-title {
  font-family: $fontName;
  font-size: 13px;
  font-weight: 500;
  color: #464457;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

#NisloModal .panel {
  width: 20%;
  padding-left: 20px;
}

/*******************/

/** Edit Summary Modal */

.selectLarge {
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d4d7e6;
  font-family: $fontName;
  font-weight: 500;
  font-size: 13px;
  color: #a1a6c0;
}

.selectLarge option {
  padding: 5px 10px;
}

.selectLarge option:after {
  font-family: 'Font Awesome 5 Free';
  content: '\f105';
  font-weight: 900;
  float: right;
  margin-top: 4px;
}

.selectMultiple option:after {
  content: '\f067';
}

.selectMultiple option:checked:after {
  content: '\f056';
}

.selectLargeSkills {
  height: 180px;
}

.selectLargeSkills option:after {
  display: none;
}

/****/

.warning-area {
  background: rgba(245, 148, 42, 0.2);
  font-family: $fontName;
  font-size: 11px;
  color: #f4a661 !important;
  font-weight: 400;
  border-radius: 4px;
}

.warning-area a {
  font-weight: 500;
  color: #f4a661 !important;
  text-decoration: underline;
}

.warning-area i {
  position: relative;
  top: 2px;
  font-size: 22px;
  margin-right: 15px;
}

.info-area {

  background: rgba(205, 37, 83, 0.2);
  font-family: $fontName;
  font-size: 11px;
  color: #cd2553 !important;
  font-weight: 400;

  border-radius: 4px;
}

.info-area a {
  font-weight: 500;
  color: #cd2553 !important;
  text-decoration: underline;
}

.info-area i {
  position: relative;
  top: 2px;
  font-size: 22px;
  margin-right: 15px;
}

.btn-find,
.btn-find:hover {
  background-color: #5c77ff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #fff !important;
  width: 100px !important;
  height: 40px !important;
}

.btn-reset,
.btn-reset:hover {
  background-color: #f5f5f9 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #6381ff !important;
  width: 100px !important;
  height: 40px !important;
  margin-right: 20px !important;
}

.btn-select,
.btn-select:hover {
  width: 100px !important;
  height: 40px !important;
  background-color: #f5f5f9 !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #6381ff !important;
}

.btn-blue,
.btn-blue:hover {
  background-color: #5c77ff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #fff !important;
  width: 140px !important;
  height: 40px !important;
}

.btn-blue.btn-small {
  font-size: 10px !important;
  width: 65px !important;
  height: 30px !important;
  padding: 0 !important;
}

.btn-white,
.btn-white:hover,
.btn-nr-white,
.btn-nr-white:hover {
  background-color: #fff !important;
  border-color: #a1a6c0 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #a1a6c0 !important;
  width: 110px !important;
  height: 40px !important;
}

.pageSubHeader {
  position: relative;
  display: flex;
  line-height: 23px;
  align-items: center;
}

.pageSubHeader .page-title {
  font-family: $fontName;
  font-weight: 500;
  font-size: 1.4rem;
  color: #464457;
  margin: 0;
}

.pageSubHeader .page-desc {
  font-family: $fontName;
  font-weight: 500;
  /*font-size: 12px;*/
  color: #464457;
  opacity: 0.5;
}

.pageSubHeader .page-desc span {
  font-family: $fontName;
  font-weight: 600;
}

.pageSubHeader .page-separator {
  width: 1px;
  height: 19px;
  background-color: #d4d7e6;
  margin: 0 20px;
}

/******/
.nislo-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

.nislo-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.nislo-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  border: solid 1px #d1d7e2;
  background-color: #eceff4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.nislo-switch .slider:before {
  position: absolute;
  content: '';
  width: 14px;
  height: 14px;
  left: 1px;
  bottom: 1px;
  background-color: #d1d7e2;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.nislo-switch input:checked+.slider {
  border: solid 1px #2fbd6d;
  background-color: #a7e6c3;
}

.nislo-switch input:checked+.slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
  background-color: #50ce87;
}

.nislo-switch-panel {
  position: relative;
  display: flex;
}

.nislo-switch-panel .title {
  font-family: $fontName;
  font-weight: 500;
  color: #6c7293;
  padding: 0 10px;
  font-size: 1rem;
}

/*********/
.btnTabArea {
  position: relative;
  display: flex;
}

.btnTabArea a {
  position: relative;
  display: block;
  margin-left: 10px;
  width: 29px;
  height: 29px;
  text-indent: -9999px;
  /* background: url(assets/media/nisloimg/tabViewSprite.png) 0 0 no-repeat; */
}

.btnTabArea a.listview {
  background-position: 0 0;
}

.btnTabArea a.cardview {
  background-position: -30px 0;
}

.btnTabArea a.listview.selected {
  background-position: 0 -30px;
}

.btnTabArea a.cardview.selected {
  background-position: -30px -30px;
}

/*****/

/**/

table.nislo-table-cardview tbody td {
  vertical-align: top;
}

table.nislo-table-cardview tbody td.fieldName .fieldNameInner {
  flex-direction: column;
  justify-content: center;
}

table.nislo-table-cardview .name-badge {
  margin: 0 0 10px 0;
}

table.nislo-table-cardview .writer-name span {
  margin: auto;
}

table.nislo-table-cardview .fieldScore .scoreArea {
  font-family: $fontName;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

table.nislo-table-cardview .fieldScore .scoreArea span {
  font-weight: 500;
  font-size: 11px;
  color: #a7abc3;
}

table.nislo-table-cardview .fieldScore .scoreArea strong {
  font-weight: 600;
  font-size: 24px;
  color: #6c7293;
}

/**/

table.nislo-table-cardview .detail {
  font-size: 12px;
  font-weight: 500;
  color: #a8abbd;
  margin: 20px 0 10px;
}

table.nislo-table-cardview .detail span {
  font-size: 22px;
  color: #6c7293;
}

/**/

.nislo-datatable tbody.color-line tr.selectable-row:hover td {
  background-color: #f5f5f9;
  cursor: pointer;
}

.nislo-datatable tbody.color-line tr.selectable-row.highlight td {
  background-color: #8297ff;
  color: #fff;
}

.table-responsive tbody tr:nth-child(odd) td {
  background-color: rgba(247, 248, 249, 0.5);
  /*
  background: repeating-linear-gradient(
    45deg,
    #FAFAFA,
    #FAFAFA 5px,
    #fff 5px,
    #fff 10px
  );
  */
}

.writerSearchTable .table-responsive tbody tr:nth-child(odd) td {
  background-color: rgba(247, 248, 249, 0.2);
}

.table-responsive thead tr.filter th {
  background-color: #f7f8f9;
}

/**/
.nislo-datatable .feedArea {
  display: flex;
  font-size: 11px;
  font-weight: 500;
  color: #6c7293;
  margin-bottom: 10px;
}

.nislo-datatable .feedArea .feedName {
  width: 35%;
}

/***/

.table-workload th {
  font-size: 12px;
  font-weight: 500;
}

.table-workload th,
.table-workload td {
  padding: 10px;
  border-right: 1px solid #d4d7e6;
  border-bottom: 1px solid #f2f3f8;
}

.table-workload th.bdr,
.table-workload td.bdr {
  border-right: 1px solid #f5f5f9;
}

.table-workload th:nth-last-child(-n + 2),
.table-workload td:nth-last-child(-n + 2) {
  padding-right: 0;
  border-right: none;
}

.table-workload tr.date-area th {
  color: #a1a6c0;
  padding-bottom: 30px;
  border-bottom: none;
}

.table-workload tbody td {
  font-size: 11px;
  font-weight: 600;
  color: #595d6e;
}

.table-workload tbody td:first-child {
  font-weight: 500;
  color: #494b74;
}

.table-workload tbody td.text-red {
  color: #db7979;
}

.table-workload tbody td.text-yellow {
  color: #f4a661;
}

.table-workload tbody td.text-blue {
  color: #5f7cf6;
}

.table-workload tbody td.text-green {
  color: #50ce87;
}

.table-workload .badge-supervisor {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  border: solid 1px #a1a6c0;
  background-color: #f6f6f6;
  line-height: 30px;
  text-align: center;
  border-radius: 30px;
  margin-right: 5px;
  font-family: $fontName;
  font-size: 9.6px;
  font-weight: 600;
  color: #595d6e;
}

/***/

.badge-green {
  color: #308f5a;
  background-color: #c8f3cf;
}

.badge-yellow {
  color: #969049;
  background-color: #fbf38d;
}

.badge-orange {
  color: #c57e3f;
  background-color: #f9d2b0;
}

.badge-gray {
  color: #a1a6c0;
  background-color: #e9ebf2;
}

.badge-red {
  color: #d87087 !important;
  background-color: #ebb7c3;
}

.badge-blue {
  color: #fff !important;
  background-color: #3161a9;
}

.badge-purple {
  color: #fff !important;
  background-color: #a531a9 !important;
}

.badge-darkblue {
  background-color: #6c7293;
}

.badge-darkred {
  background-color: #d66377;
}

.badge-darkyellow {
  background-color: #d7aa32;
}

.badge-darkgreen {
  background-color: #469c6b;
}

.projects-nav-container {
  display: flex;
  justify-content: space-between;
}

ul.writer-profile-tab-nav {
  width: 100%;
  background-color: #e5e7f0;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

ul.writer-profile-tab-nav li {
  position: relative;
  display: inline-block;
  background-color: transparent;
}

ul.writer-profile-tab-nav li a {
  padding: 12px 10px;
  font-size: 12px;
  font-weight: 500;
  color: #a1a6c0;
  min-width: 180px;
}

ul.writer-profile-tab-nav li a span {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 600;
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.7);
}

ul.writer-profile-tab-nav li a span:before {
  position: absolute;
  display: block;
  content: ' ';
  width: 8px;
  height: 8px;
  border-radius: 10px;
  top: 0;
  left: 0;
}

ul.writer-profile-tab-nav li a span.online:before {
  background-color: #50ce87;
}

ul.writer-profile-tab-nav li a span.offline:before {
  background-color: #d87087;
}

ul.writer-profile-tab-nav li a span.away:before {
  background-color: #f4a661;
}

ul.writer-profile-tab-nav li a.active {
  color: #6c7293;
  background-color: #fff;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nislo-tab-content {
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

/* writer list level 2*/
ul.writer-profile-section-nav {
  width: 100%;
  border-bottom: 1px solid #f2f3f8;
  padding: 0 30px;
}

ul.writer-profile-section-nav li {
  margin-right: 30px;
}

ul.writer-profile-section-nav li a {
  color: #a1a6c0;
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0 14px 20px;
}

ul.writer-profile-section-nav li a.active {
  color: #2b2b43;
  border-bottom: 4px solid #7d92ff;
}

.nislo-section-content {
  padding: 30px;
}

/* writer profile page objects */

.writer-dashboard {
  position: relative;
  padding: 30px;
}

.writer-dashboard .panel-container,
.panel-container {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.writer-dashboard hr.seperator {
  margin: 2em 0;
}

.writer-dashboard .status-text .text {
  padding: 0.5em 0 0.5em 1.5em;
  font-size: 12px;
  font-weight: 500;
  color: #a1a6c0;
}

.writer-dashboard .status-text .text span {
  color: #6c7293;
}

/**/

.writer-dashboard .panel-container .qview {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.writer-dashboard .panel-container .qview .qstatus {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  border: 2px solid black;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  color: #a1a6c0;
}

.writer-dashboard .panel-container .qview .qstatus.border-darkblue {
  border-color: #6c7293;
}

.writer-dashboard .panel-container .qview .qstatus.border-darkred {
  border-color: #d66377;
}

.writer-dashboard .panel-container .qview .qstatus.border-darkyellow {
  border-color: #d7aa32;
}

.writer-dashboard .panel-container .qview .qstatus.border-darkgreen {
  border-color: #469c6b;
}

.writer-dashboard .panel-container .qview .qstatus.border-gray {
  border-color: #a1a6c0;
}

.writer-dashboard .panel-container .qview .qtext {
  font-size: 12px;
  font-weight: 500;
  color: #494b74;
}

.writer-dashboard .panel-container .qview .qtext span {
  display: block;
  color: #a8abbd;
}

/**/

/* niso-form */

.nislo-form {
  padding: 5px 0;
}

.nislo-form label {
  display: block;
  font-size: 13px;
  color: #595d6e;
  margin-bottom: 10px;
}

.nislo-form .bootstrap-select {
  width: 100% !important;
}

.nislo-form .bootstrap-select.disabled button {
  border-color: #ebedf2 !important;
}

.nislo-form .kt-checkbox>span:after {
  border-color: #8297ff;
}

.explain {
  display: flex;
  align-items: center;
  height: 90px;
  padding: 0 40px;
  margin: 30px 0;
  border-radius: 4px;
  background-color: #f5f5f9;
}

.explain i {
  margin-right: 10px;
  font-size: 16px;
  color: #8297ff;
}

/*****/

.is-invalid,
input.invalid,
textarea.invalid,
.ng-select.invalid .ng-select-container,
.ng-select.is-invalid .ng-select-container,
.is-invalid .ng-number-picker .input-group-prepend .input-group-text,
.is-invalid .ng-number-picker .form-control {
  border-color: #fd397a !important;
}

.ng-select.is-invalid~.invalid-feedback,
.is-invalid~.invalid-feedback {
  display: block;
}

.form-control.is-invalid {
  background-image: none;
}

.form-control.is-invalid.ico-calendar {
  background: url(assets/media/nisloimg/ico-calendar.svg) 97% center no-repeat;
}

.form-control.is-invalid.ico-list {
  background: url(assets/media/nisloimg/ico-projects.svg) 97% center no-repeat;
}

div.is-invalid {
  color: #fd397a;
}

.invalid-feedback {
  font-size: 12px;
}

/*********
ng-select update
*********/

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-weight: 400;
  font-size: 12px;
}

.ng-select .ng-clear-wrapper .ng-clear {
  line-height: unset !important;
}

.ng-select .ng-select-container {
  min-width: 10rem;
}

/*******************************
ng-number-picker style
*******************************/

.ng-number-picker .input-group-prepend .input-group-text {
  background: #fff;
  border-color: #d4d7e6;
}

.ng-number-picker .form-control {
  border-left: none;
  border-right: none;
  text-align: center;
}

/*
  complete
*/

.score-label-border-panel div.panel {
  border-right: 1px solid #dee2e6;
}

.score-label-border-panel div.panel:last-child {
  border: none;
}

/* clearAllFilters button */

.clearAllFilters {
  position: relative;
  display: block;
  text-align: center;
  cursor: pointer;
  background-color: #5c77ff !important;
  color: #fff !important;
  border: 1px solid #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  padding: 4px 10px;
}

.clearAllFilters i {
  margin-right: 5px;
}

/*
.clearAllFilters:hover { color:#5c77ff!important; background-color: #fff; border:1px solid #5c77ff}
*/

.item-desc {
  font-size: 1rem;
  color: #a7abc3;
  padding-bottom: 0.5rem;
  font-weight: 500;
  display: block;
}

.item-value {
  font-size: 1.4rem;
  font-weight: 600;
  color: #6c7293;
  display: block;
}

.tooltipFavorites .tooltip-inner {
  white-space: nowrap;
  max-width: 400px;
}

.tooltipCustomAuthMsg .tooltip-inner {
  background-color: #1c1c28 !important;
  color: #fff;
  padding: 2px 5px !important;
  font-size: 11px;
}

.tooltipCustomAuthMsg .arrow::before {
  border-top-color: #1e1e2d;
}

button[disabled] {
  cursor: url('~/assets/media/nisloimg/ico-not-allowed.png'), auto !important;
}

.button-transparent {
  padding: 0;
  border: none;
  background: none;
}

/********/

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.custom-day.focused {
  background-color: #e6e6e6;
  /*background-color: rgb(2, 117, 216)!important;*/
}

.custom-day.range,
.custom-day:hover {
  /*background-color: rgb(2, 117, 216)!important;*/
  background-color: #5c77ff;
  color: white !important;
}

.custom-day.faded {
  background-color: rgba(92, 119, 255, 0.5);

  /*
  background-color: rgba(2, 117, 216, 0.5)!important;
  */
}

.dateRangeTagView {
  background-color: #ebf5ff;
  border-radius: 2px;
  position: absolute;
  display: inline-block;
  font-size: 11px !important;
  color: #595d6e;
  font-weight: 300;
  padding: 1px 5px;
  top: 50%;
  height: 19px;
  margin-top: -10px;
  left: 5%;
}

.ngb-dp-navigation-chevron {
  color: #6381ff;
}

.ngb-dp-month-name {
  color: #2b2b43 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.datepickerMainDiv {
  background: transparent;
}

.popoverClass .popover-body .btn-remove {
  border: 1px solid #d4d7e6 !important;
  border-radius: 20px !important;
  padding: 5px 10px 5px 25px !important;
  font-size: 12px !important;
  color: #959cb6 !important;
  background-color: #fff !important;
  background-image: url('~/assets/media/nisloimg/cross.png') !important;
  background-position: 5px center !important;
  background-repeat: no-repeat !important;
}

/************/

@media screen and (max-width: 1280px) {
  .writer-dashboard .status-text {
    flex-direction: column;
  }

  .writer-dashboard .status-text .text {
    padding: 0 0 0.3em 0;
  }

  .report-list ul li {
    margin-bottom: 1px;
  }
}

@media (max-width: 1024px) {
  .kt-aside-close:hover {
    background-color: transparent;
  }

  .kt-header-mobile__logo img {
    height: 40px;
  }

  .kt-header__topbar .fieldBadgeTopBar {
    padding: 0 !important;
  }

  .kt-header__topbar .fieldBadge .name-badge {
    width: 35px !important;
    height: 35px !important;
  }

  #kt_login .kt-grid--hor-tablet-and-mobile {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .kt-aside {
    background: #1a1a27;
  }

  .kt-header__topbar {
    background-color: #1a1a27;
    margin-top: -80px;
    right: 47px;
    z-index: 100 !important;
    left: unset;
    justify-content: right;
    width: 60px;
  }

  .kt-aside-menu-wrapper {
    margin-top: 40px;
  }

  .kt-aside--on .kt-aside-close {
    right: 230px;
    margin-top: 15px;
    background: #1e1e2d;
  }

  .kt-aside--on .kt-aside-close span {
    transform: rotate(180deg);
  }

  .kt-aside--on .kt-aside-close svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #494b74;
  }

  .kt-aside--on .kt-aside-close svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }

  .kt-aside--on .kt-aside-close:hover svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }

  .kt-aside--on .kt-aside-close:hover svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }

  .project-body .behavior-panel .list-panel .list-panel-item div.title {
    padding: 10px 5px 10px 10px !important;
    width: 124px !important;
  }

  .project-body .list-item div:first-child {
    width: 124px !important;
  }

  .project-body .behavior-panel .list-panel .list-panel-item div.desc {
    width: calc(100% - 125px) !important;
    padding: 10px 5px 10px 10px !important;
  }

  .project-header,
  .project-footer {
    min-width: unset !important;
  }

  table.nislo-datatable .detail {
    display: flex;
  }

  #NisloModal.modal-mediumx {
    width: 750px;
  }

  #NisloModal.modal-largest {
    width: 750px;
  }
}

.btn-secondary {
  background-color: transparent !important;
  border: 1px solid #ebedf2 !important;
  color: #6c7293 !important;
}

.toast-top-center {
  bottom: 0;
  margin: 0 auto;
  right: 0;
  left: 0;
  width: 100%;
}

.toast-container {
  z-index: 99999 !important;
}

/*
@media only screen and (orientation:portrait){
  body {
    height: 100vw;
    transform: rotate(90deg);
    overflow-y: hidden;
  }
  .kt-login {
    height: 100vw;
    width: 100vh;
    vertical-align: top
  }
  .kt-header-mobile--fixed .kt-header-mobile {
    width: 100vh;
    vertical-align: top
  }

  .kt-aside {
    right: -55vh;
  }

  .kt-aside-overlay {
    width: 100vh;
    vertical-align: top
  }
}
*/


.btn-outline-primary{
  height: 40px;
}

#paymentPlansTable table {
  width: 100% !important;
}